import React from 'react';
import {SectionText, SectionTitle} from '../../atoms';
import {
  Container,
  PageContainer,
  ColorWrapper,
  TextSpaceMargin,
  ImageMove,
} from './styles';
import pic1 from './images/1.jpg';
import pic2 from './images/2.jpg';
import pic3 from './images/3.jpg';
import pic4 from './images/4.jpg';
import pic5 from './images/5.jpg';
import pic6 from './images/6.jpg';
import MovedImg from './images/grand_prix.png';
import Gallery from '../Gallery/Gallery';
import PlayerGrey from '../../atoms/PlayerGrey/PlayerGrey';
import img1 from './images/Ekspozycja w pawilonie polskim. kopia.jpg';
import img2 from './images/Jan Szczepkowski na tle montażu kapliczki na wystawie paryskiej kopia.jpg';
import img3 from './images/Jan Szczepkowski w okresie wystawy paryskiej.jpg';
import img4 from './images/Kapliczka Bożego Narodzenia NAC kopia.jpg';
import img5 from './images/Kapliczka Bożego Narodzenia w Pawilonie Polskim, 1925 r. kopia.jpg';

const GrandPrixPage = ({bgColor}) => {
  return (
    <PageContainer backgroundColor={bgColor}>
      <Container>
        <ImageMove src={MovedImg} />
        <TextSpaceMargin>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <SectionTitle color="#525252">GRAND PRIX</SectionTitle>
            <PlayerGrey src={require('../../../audio/Grand Prix.mp3')} />
          </div>
          <SectionText color="#525252" style={{paddingTop: '1.5rem'}}>
            &emsp;&emsp;Jan Szczepkowski had his breakthrough in 1925. At the
            International Exhibition of Decorative Arts and Modern Industry
            (l'Exposition internationale des Arts décoratifs et industriels
            modernes) which was organized on 28th of April in Paris, the artist
            got rewarded with a Grand Prix for the Chapel of Nativity and with a
            Diplome d’Honneur for his teaching system.
            <br />
            <br />
            &emsp;&emsp;The tradition of organizing exhibitions in the
            contemporary way had it’s beginning in London in 1851. The Parisian
            Exhibition had been originally planned for 1915 but unfortunately
            got rescheduled due to the start of World War I. When it finally
            opened in 1925 it proved to be a great chance for an international
            debut of Poland: an opportunity to show the world freshly reborn
            nation’s independent thinking and creative force. Jerzy Warchałowski
            in May 1921, wrote about the importance of emphasizing Polish
            individuality and the need to find its well-deserved place among
            other independent nations through Polish art and technology.
            <br />
            <br />
            &emsp;&emsp;Jerzy Warchałowski was appointed as General Polish
            Delegate to the Exhibition and his choice of artists shaped the
            style of Polish display, seeking the unique aesthetic of a newly
            reborn country. He succeeded in finding it. It was truly a new era
            for Polish art.
            <br />
            <br />
            &emsp;&emsp;Yet the 1925 Exhibition has changed much more things.
            The Art Nouveau, that had ruled since XIX century, with its free
            composition, asymmetry and floral ornaments was forgotten. Instead,
            Art déco claimed the place of the main artistic style, represented
            by smooth yet sharp geometric shapes mainly in interior design.
            According to French sources of information, the Exhibition was
            visited by 16 million guests.
          </SectionText>
        </TextSpaceMargin>
      </Container>
      <ColorWrapper bgColor="#525252" style={{marginTop: '3rem'}}>
        <Container>
          <Gallery
            isFlex
            hoverColor="rgba(224, 200, 135, 0.4)"
            colorSign="#fbfbfb"
            images={[
              {
                src: pic1,
                sign:
                  'Budowa pawilonu polskiego. Przed budynkiem członkowie komitetu organizacyjnego, od lewej: doradca techniczny, architekt Tadeusz Stryjeński (ówczesny teść Zofii Stryjeńskiej); komisarz generalny sekcji polskiej Jerzy Warchałowski, delegat Rządu Polskiego do spraw wystawy sztuki dekoracyjnej, rzeźbiarz Ludwik Puget; autor projektu pawilonu polskiego architekt Józef Czajkowski. 1925 R.',
                width: '30.1%',
              },
              {
                src: pic2,
                sign:
                  'Komisarz generalny sekcji polskiej Jerzy Warchałowski przed wejściem budowanego pawilonu polskiego. 1925 R.',
                width: '31.35%',
              },
              {
                src: pic3,
                sign:
                  'Otwarcie polskiego pawilonu podczas międzynarodowej wystawy. Widok zewnętrzny. 1925 R.',
                width: '33.1%',
              },
            ]}
          />
        </Container>
      </ColorWrapper>
      <ColorWrapper bgColor={'#E0C887'}>
        <Container style={{paddingTop: '3rem'}}>
          <SectionText color="#525252">
            &emsp;&emsp;Such uniformity of Polish pavilion combined with the
            high quality of the presented artwork was awarded with many prizes.
            Because of that, there are some inaccuracies when it comes to their
            actual amount. According to Jerzy Warchałowski Polish exhibits won
            189 prizes, among them: 36 Grand-Prix prizes, 31 honorary diplomas,
            60 gold medals. What is more, Poland got the biggest amount of
            Grand-Prix prizes in interior design projects (6) and in the textile
            section, ex aequo with Austria (7). Mieczysław Tretera, on the other
            hand, counted 172 awards. He explained it by stating that there were
            actually 251 Polish exhibitors officially registered. He excluded 56
            art schools’ professors from the count who did not receive any
            prizes. The third comment, comes from Anna M. Dexler and Andrzej K.
            Olszewski. They calculated the biggest amount of 205 awards,
            including: 35 Grand Prix prizes, 31 honorary diplomas, 70 gold
            medals, 56 silver medals and 13 brown medals.
            <br />
            <br />
            &emsp;&emsp;Other notable contestants were the students of the
            Zakopane school. They were awarded for wooden statuettes, referring
            to the art of Podhale region. <br />
            <br />
            &emsp;&emsp;Nevertheless, the greatest successes for Poles was the
            purchase of Szczepkowski's Chapel of Nativity by the French
            Government. He was awarded with the Grand Prix and Legion of Honour.
            It was the start of the busiest period in his career.
          </SectionText>
          <Gallery
            maxWidth={'60'}
            columnsNumber={2}
            isImgFit
            hoverColor="rgba(224, 200, 135, 0.4)"
            colorSign="#525252"
            images={[
              {
                src: pic4,
                sign: 'Grand Prix',
              },
              {
                src: pic5,
                sign: 'Legia Honorowa',
              },
            ]}
          />
          <SectionText color="#525252">
            &emsp;&emsp;Warchałowski considered the Chapel to be perfectly in
            tune with folk doctrine, inspired by the style of Zakopane and
            folklore themes. At the same time, it remained modern, fresh.
            <br />
            <br />
            &emsp;&emsp;As it was mentioned earlier, the Chapel was purchased by
            the French Government, though they paid the third of the prearranged
            prize (total of 25 000 francs). Szczepkowski wanted to display it in
            the Museum of Decorative Arts in Louvre or in one of the churches in
            Paris. Ultimately it was placed in a Polish Church in Dourges. The
            town is in the Pas-de-Calais region, about a hundred kilometers from
            Paris.
          </SectionText>
          <Container style={{marginTop: '2rem'}}>
            <p
              style={{
                fontFamily: 'LATOREGULAR',
                fontSize: '10px',
                lineHeight: 1.2,
                textAlign: 'justify',
                color: '#525252',
              }}>
              Information sources:
              <br />
              <br />
              Chrudzimska – Uhera Katarzyna, JAN SZCZEPKOWSKI, ŻYCIE I
              TWÓRCZOŚĆ, Urząd Miejski w Milanówku, 2008 r.
              <br />
            </p>
            <a
              style={{
                fontFamily: 'LATOREGULAR',
                fontSize: '10px',
                lineHeight: 1.2,
                textAlign: 'justify',
                color: '#525252',
                marginTop: '10px',
              }}
              href={'https://culture.pl/pl/artykul/pawilony-polskie'}>
              https://culture.pl/pl/artykul/pawilony-polskie
            </a>
            <br />
            <a
              style={{
                fontFamily: 'LATOREGULAR',
                fontSize: '10px',
                lineHeight: 1.2,
                textAlign: 'justify',
                color: '#525252',
              }}
              href={
                'https://wilno.tvp.pl/47787010/95-lat-temu-na-wystawie-w-paryzu-narodzilo-sie-polskie-art-deco'
              }>
              https://wilno.tvp.pl/47787010/95-lat-temu-na-wystawie-w-paryzu-narodzilo-sie-polskie-art-deco
            </a>
            <br />
            <a
              style={{
                fontFamily: 'LATOREGULAR',
                fontSize: '10px',
                lineHeight: 1.2,
                textAlign: 'justify',
                color: '#525252',
              }}
              href={
                'https://opolnocywparyzu.pl/miedzynarodowa-wystawa-sztuki-dekoracyjnej-i-wzornictwa-1925-w-paryzu/'
              }>
              https://opolnocywparyzu.pl/miedzynarodowa-wystawa-sztuki-dekoracyjnej-i-wzornictwa-1925-w-paryzu/
            </a>
          </Container>
          <Gallery
            style={{gridGap: '2rem 1rem'}}
            maxWidth={'100'}
            columnsNumber={3}
            isImgFit
            hoverColor="rgba(224, 200, 135, 0.4)"
            colorSign="#525252"
            images={[
              {
                src: pic6,
                sign:
                  ' Ekspozycja w&nbsp;pawilonie polskim. Kaplica autorstwa rzeźbiarza, prof. Jana Szczepkowskiego.',
              },
              {
                src: img1,
                sign: 'Ekspozycja w pawilonie polskim',
              },
              {
                src: img2,
                sign:
                  'Jan Szczepkowski na tle montażu kapliczki na wystawie paryskiej',
              },
              {
                src: img3,
                sign: 'Jan Szczepkowski w okresie wystawy paryskiej',
              },
              {
                src: img4,
                sign: 'Kapliczka Bożego Narodzenia',
              },
              {
                src: img5,
                sign:
                  'Kapliczka Bożego Narodzenia w Pawilonie Polskim, 1925 r.',
              },
            ]}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingBottom: '3rem',
            }}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/bNltcmTQ70c"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </Container>
      </ColorWrapper>
    </PageContainer>
  );
};

export default GrandPrixPage;
