import React from 'react';
import SEO from '../../components/seo';
import GrandPrixPageEN from '../../components/desktop/GrandPrixPage/GrandPrixPageEN';

const Artist = () => {
  return (
    <>
      <SEO title={'1925'} />
      <GrandPrixPageEN bgColor="#E0C887" />
    </>
  );
};

export default Artist;
